import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { BlogPostType } from "../components/blog/blogPostSection"
import TopSection from "../components/common/topSection"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TheAppSection from "../components/theApp/theAppSection"
import Wave, { WaveType } from "../components/ui/wave"
import { PageType } from "./about"

const TheApp = ({path}) => {
  const { graphCmsPage, allGraphCmsBlogPost } = useStaticQuery(graphql`
    {
      graphCmsPage(id: { eq: "Page:ckrafe3q00lus0b53jfh49qqs:en:PUBLISHED" }) {
        heading
        body {
          html
        }
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
          }
          focusKeywords
        }
      }
      allGraphCmsBlogPost(
        sort: { order: DESC, fields: createdAt }
        filter: {
          stage: { eq: PUBLISHED }
          blogCategories: {
            elemMatch: {
              id: { eq: "BlogCategory:ckrbvuew09tpr0c510zi18yo0:PUBLISHED" }
            }
          }
          locale: { eq: en }
        }
      ) {
        nodes {
          blogCategories {
            category
            color
            slug
          }
          body {
            html
          }
          heading
          summary
          slug
          picture {
            alt
            id
            url
            localFile {
              childrenImageSharp {
                gatsbyImageData(width: 596, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `)

  const data: PageType = graphCmsPage
  const posts: BlogPostType[] = allGraphCmsBlogPost.nodes

  return (
    <Layout path={path}>
      <SEO title="The app" meta={data.seo} />

      <TopSection heading={data.heading} body={data.body} />

      <div className="container pb-5">
        <TheAppSection posts={posts} />
      </div>
      <Wave pathClassName={"u-c-orange"} type={WaveType.v6} />
    </Layout>
  )
}

export default TheApp
